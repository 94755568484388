import React from "react";
import PropTypes from "prop-types";

const Dates = ({start, end, ...props}) => {
    return (
        <div {...props}>
            {!end || end === start ? (
                <>
                    <span className="single-date-label">le&nbsp;</span>
                    <span className="single-date-date">{start}</span>
                </>
            ) : (
                <>
                    <span className="start-date-label">du&nbsp;</span>
                    <span className="start-date-date">{start}</span>
                    <span className="end-date-label">&nbsp;au&nbsp;</span>
                    <span className="end-date-date">{end}</span>
                </>
            )}
        </div>
    )
}

Dates.propTypes = {
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
}

export default Dates
