import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import EventListItem from "../ListItems/EventListItem";

const EventsPreview = () => {
    const data = useStaticQuery(graphql`{
      events: allNodeEvenement(
          sort: {fields: [field_date___value, field_date___end_value], order: ASC},
          filter: {is_forthcoming: {eq: true}, status: {eq: true}},
          limit: 3,
      ) {
          nodes {
              title
              path {
                  alias
              }
              dates: field_date {
                  value(formatString: "DD/MM/Y")
                  end_value(formatString: "DD/MM/Y")
              }
              emplacement: field_emplacement
              relationships {
                  image: field_image {
                      localFile {
                          childImageSharp {
                              gatsbyImageData(
                                  width: 480
                                  height: 270
                                  quality: 100
                                  transformOptions: {cropFocus: CENTER}
                                  layout: CONSTRAINED
                              )
                          }
                      }
                  }
              }
          }
      }
  }`)

    return (
        <>
            {data.events.nodes.length > 0 ? (
                <>
                    <h2 className="title is-2 is-uppercase">Agenda</h2>
                    <div className="subtitle">Dates à retenir</div>

                    <div className="columns">
                        {data.events.nodes.map((node, index) => (
                            <div key={index} className="column is-6 is-4-desktop">
                                <EventListItem content={node}/>
                            </div>
                        ))}
                    </div>

                    <div className="more-link has-text-centered">
                        <Link to={`/agenda`} className="button is-primary is-rounded is-uppercase">
                            Voir l'agenda complet
                        </Link>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default EventsPreview
