import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import Small16by9 from "../Images/Small16by9";
import Dates from "../Dates";

const EventListItem = ({ content }) => (
  <div className="card event-list-item">
    <div className="card-image">
      <Link to={content.path.alias}>
        {content.relationships.image ? (
            <GatsbyImage
                image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                alt={content.title} />
        ) : (
          <Small16by9 alt={content.title}/>
        )}
      </Link>
    </div>
    <div className="card-content">
      <div className="tags are-medium">
        <span className="tag is-primary is-uppercase">
            <Dates start={content.dates.value} end={content.dates.end_value}/>
        </span>
      </div>
      <div className="title is-3 is-uppercase">
        <Link to={content.path.alias}>{content.title}</Link>
      </div>
      {content.emplacement && (
        <div className="emplacement">
        <span className="icon">
          <i className="fas fa-map-marker-alt"></i>
        </span>
          <span className="has-text-primary is-uppercase">{content.emplacement}</span>
        </div>
      )}
    </div>
  </div>
)

EventListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default EventListItem
