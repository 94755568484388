import React from "react";
import {Link} from "gatsby";
import Layout from "../components/Layout";
import Block from "../components/Block";
import ArticlesPreview from "../components/Blocks/ArticlesPreview";
import EventsPreview from "../components/Blocks/EventsPreview";
import PublicationsPreview from "../components/Blocks/PublicationsPreview";
import QuickLinkMenu from "../components/Menus/QuickLinkMenu";
import Metas from "../components/Metas";

const IndexPage = () => (
    <Layout heroSize="medium" title="Bienvenue sur le site de la commune de Lumes">
        <Metas title="Accueil" description="Site internet de la commune de Lumes. Retrouvez tous les services et démarches, les informations pratiques, les actualités et événements de la commune des Ardennes." />
        <Block id="articles-preview--block" background={`half-light`} container section>
            <ArticlesPreview/>
        </Block>
        <Block id="quick-link--block" background={`primary`} className={`has-text-white`} container section>
            <h2 className="title is-2 has-text-centered is-sr-only">Liens rapides</h2>
            <QuickLinkMenu/>
        </Block>
        <Block id="events-preview--block" container section className="has-background-image--contain-center"
               style={{backgroundImage: 'url(/dots.png)'}}>
            <EventsPreview/>
        </Block>
        <Block id="sms-alert--block" background={`light`} container section>
            <div className="columns is-multiline is-vcentered">
                <div className="column is-12 is-5-desktop">
                    <h2 className="title is-2 is-uppercase"><span>Restez <br/>Informés</span></h2>
                </div>
                <div className="column is-12 is-5-desktop">
                    <h3 className="title is-5">Alertes SMS</h3>
                    <p><strong>Vous souhaitez être informé d’un problème d’intempérie, d’une fermeture de route ou
                        d’école, ou d’un évènement particulier ?</strong></p>
                    <p>La commune vous informe par SMS.</p>
                </div>
                <div className="column is-12 is-2-desktop">
                    <Link to={'/la-mairie/alertes-sms'} className={"button is-rounded is-primary"}>Je m'abonne</Link>
                </div>
            </div>
        </Block>
        <Block id="publications-preview--block" container section>
            <PublicationsPreview/>
        </Block>
    </Layout>
)

export default IndexPage;
