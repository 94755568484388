import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import ArticleListItem from "../ListItems/ArticleListItem";

const ArticlesPreview = () => {
  const data = useStaticQuery(graphql`{
      articles: allNodeArticle(
          limit: 3,
          filter: {status: {eq: true}},
          sort: {fields: created, order: DESC}
      ) {
          nodes {
              title
              body {
                  summary
                  value
              }
              path {
                  alias
              }
              relationships {
                  image: field_image {
                      localFile {
                          childImageSharp {
                              gatsbyImageData(
                                  width: 555
                                  height: 312
                                  quality: 100
                                  transformOptions: {cropFocus: CENTER}
                                  layout: CONSTRAINED
                              )
                          }
                      }
                  }
                  tags: field_tags {
                      name
                      path {
                          alias
                      }
                  }
              }
          }
      }
  }`)

  return (
    <>
      <div className="title is-2 is-uppercase has-text-centered">Actualités</div>
      <div className="subtitle has-text-centered">Tout savoir sur la commune</div>

      <div className="columns">
        {data.articles.nodes.map((node, index) => (
          <div key={index} className="column is-6 is-4-desktop">
            <ArticleListItem content={node} />
          </div>
        ))}
      </div>

      <div className="more-link has-text-centered">
        <Link to={`/actualites`} className="button is-rounded has-text-primary is-uppercase">
          Voir nos actualités
        </Link>
      </div>
    </>
  )
}

export default ArticlesPreview
