import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import PublicationListItem from "../ListItems/PublicationListItem";

const PublicationsPreview = () => {
  const data = useStaticQuery(graphql`{
      publications: allNodePublication(
          limit: 3,
          filter: {status: {eq: true}},
          sort: {fields: created, order: DESC}
      ) {
        nodes {
          title
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 335
                    height: 473
                    quality: 100
                    transformOptions: {cropFocus: CENTER}
                    layout: CONSTRAINED
                  )
                }
              }
            }
            file: field_fichier {
              localFile {
                publicURL
                prettySize
              }
            }
          }
        }
      }
  }`)

  return (
    <>
      <h2 className="title is-2 is-uppercase has-text-centered">Publications</h2>
      <div className="columns">
        {data.publications.nodes.map((node, index) => (
          <div key={index} className="column is-4-desktop is-6 publication">
            <PublicationListItem content={node} />
          </div>
        ))}
      </div>

      <div className="more-link has-text-centered">
        <Link to={`/publications`} className="button is-primary is-rounded is-uppercase">
          Voir les publications
        </Link>
      </div>
    </>
  )
}

export default PublicationsPreview
