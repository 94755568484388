import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import MenuLink from "../MenuLink";
import {iconInvisibleText} from "../../Utils/functions";

const QuickLinkMenu = () => {
  const data = useStaticQuery(graphql`{
      quickLinkMenu: allMenuLinkContentMenuLinkContent(
          filter: {enabled: {eq: true},
              menu_name: {eq: "liens-rapides"}},
          sort: {fields: weight, order: ASC}
      ) {
          nodes {
              title
              link {
                  uri
                  options {
                      attributes {
                          data_has_icon
                          data_icon
                          data_icon_size
                          data_text_invisible
                          target
                      }
                  }
              }
          }
      }
  }`)

  return (
    <ul id="quick-link--menu"
        className="menu is-inline is-centered is-vcentered has-column-gap">
      {data.quickLinkMenu.nodes.map((menu, index) => (
        <li className="menu-item" key={index}>
          <MenuLink internalId={menu.link.uri}
                    className="menu-link" {...menu.link.options.attributes}>
            {menu.link.options.attributes &&
            menu.link.options.attributes.data_has_icon ? (
              <>
                <span
                  className={`icon ${menu.link.options.attributes.data_icon_size}`}>
                    <i className={menu.link.options.attributes.data_icon}></i>
                </span>
                {menu.link.options.attributes.data_text_invisible
                  ? iconInvisibleText(menu.title)
                  : menu.title}
              </>
            ) : (
              <>{menu.title}</>
            )}
          </MenuLink>
        </li>
      ))}
    </ul>
  )
}

export default QuickLinkMenu
